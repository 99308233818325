/* ###### 3.19 Tooltip ###### */

.tooltip { top: 0; }

.tooltip-inner {
  font-size: 12px;
  @include border-radius(2px);
}

.tooltip-primary { @include tooltip-variant($az-color-secondary); }
.tooltip-secondary { @include tooltip-variant($az-color-secondary); }
