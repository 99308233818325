
//* ###### 3.6 Cards ###### */

.card {
  border-radius: 0;

  &.text-white,
  &.tx-white {
    .card-title { color: #fff; }
  }
}

.card-header,
.card-footer {
  position: relative;
  border-color: $gray-300;
  padding-left: 15px;
  padding-right: 15px;
}

.card-header {
  border-bottom: 0;
  &:first-child { border-radius: 0; }
}

.card-header-tab {
  border-bottom: 0;
  padding: 0;
}

.card-title {
  font-weight: 700;
  color: $gray-900;
}

.card-title-card {
  font-weight: 600;
  color: $gray-800;
  line-height: 1.5;
  font-size: 15px;
}
